import {
  JourneyTileFragment,
  JourneyPageFragment,
  MilestonePageFragment,
  JourneyTilePageDataQuery,
} from 'gatsby-graphql-types-generated'
import {graphql, navigate} from 'gatsby'
// import {useLocation} from '@reach/router'

import Layout from 'src/components/Layout'
import {Box, Grid} from 'src/components/Box'
import {FlairProvider} from 'src/context/FlairContext'
import ArticleContent from 'src/components/ArticleContent'
import RelatedTiles from 'src/components/RelatedTiles'
// import TileComments from 'src/components/TileComments'
import JourneyTileSidebar from 'src/components/Journey/TileSidebar'
import useTrackJourneyInteraction from 'src/hooks/useTrackJourneyInteraction'
import {isLmsImplementation} from 'src/lib/util'

interface Props {
  pageContext: {
    journeyTile: JourneyTileFragment
    journey: JourneyPageFragment | MilestonePageFragment
  }
  data: JourneyTilePageDataQuery
}

export const query = graphql`
  query JourneyTilePageData($tileId: String) {
    contentfulArticleTile(id: {eq: $tileId}) {
      ...TileContent
      ...RelatedTiles
    }
  }
`

const JourneyTilePage = ({
  pageContext: {journeyTile, journey},
  data,
}: Props) => {
  const isLms = isLmsImplementation()

  useTrackJourneyInteraction(journeyTile.__typename, journeyTile.contentful_id)
  const tile = data.contentfulArticleTile
  if (!tile) {
    navigate('404')
    return null
  }

  if (isLms) {
    return (
      <FlairProvider seed={parseInt(tile.id, 16)}>
        <Layout>
          <Grid
            gridTemplateColumns={{lg: '1fr'}}
            gridTemplateRows={{lg: 'auto auto'}}
            gridColumnGap="5"
          >
            <Grid gridGap="5">
              <ArticleContent tile={tile} />
              {/* Main content container */}
            </Grid>
          </Grid>
        </Layout>
      </FlairProvider>
    )
  }
  return (
    <FlairProvider seed={parseInt(tile.id, 16)}>
      <Layout>
        <Grid
          gridTemplateColumns={{lg: '320px 1fr'}}
          gridTemplateRows={{lg: 'auto auto'}}
          gridColumnGap="5"
        >
          <Grid gridGap="5">
            <ArticleContent tile={tile} />
            {/* Main content container */}

            {/* <TileComments tileId={tile.id} /> */}
          </Grid>

          {/* Sidebar content container */}
          <Box mt={{_: 5, lg: 0}} pt={{lg: 5}} gridRow={{lg: '1 / -1'}}>
            <JourneyTileSidebar journey={journey} journeyTile={journeyTile} />
          </Box>

          {/* Additional content container */}
          <RelatedTiles relatedTiles={tile.relatedTiles} />
        </Grid>
      </Layout>
    </FlairProvider>
  )
}

export default JourneyTilePage
