import React from 'react'
import {Link} from 'gatsby'
import findIndex from 'lodash/findIndex'
import {
  JourneyTileFragment,
  JourneyPageFragment,
  MilestonePageFragment,
} from 'gatsby-graphql-types-generated'
import {useLocation} from '@reach/router'

import {Body, Header4} from 'src/components/text'
import {Flex, Box} from 'src/components/Box'
import {ActionLink} from 'src/components/Action'
import JourneyIcon from 'src/components/JourneyIcon'
import {ArrowIconToRight} from 'src/components/icons'
import useViewer from 'src/hooks/useViewer'
import {
  SidebarLogin,
  SidebarContainer,
  SidebarItems,
  SidebarItem,
} from 'src/components/Tile/Sidebar'
import {isLmsImplementation} from 'src/lib/util'

type ParentJourney = Pick<
  JourneyPageFragment,
  'id' | 'name' | 'permalink' | 'icon'
>

interface NextMilestonesProps {
  milestones: JourneyTileFragment['nextMilestones']
  parentJourney: ParentJourney
}

const NextMilestones = ({milestones, parentJourney}: NextMilestonesProps) => (
  <Box p="3">
    <Body color="grey5">Other series in</Body>
    <ActionLink to={parentJourney.permalink}>
      <Flex alignItems="center" mb="2">
        {parentJourney.icon && <JourneyIcon icon={parentJourney.icon} mr="1" />}
        <Header4 fontWeight={500}>{parentJourney.name}</Header4>
      </Flex>
    </ActionLink>
    <SidebarItems items={milestones} />
    <ActionLink to={parentJourney.permalink}>
      <Flex alignItems="center">
        <Body color="grey5">See all series in this goal</Body>
        <ArrowIconToRight ml="1" width="16px" height="16px" color="grey5" />
      </Flex>
    </ActionLink>
  </Box>
)

interface NextTilesProps {
  parentJourney: ParentJourney
  milestone: MilestonePageFragment
  tileItems: SidebarItem[]
}

const NextTiles = ({parentJourney, milestone, tileItems}: NextTilesProps) => (
  <Box p="3">
    <Link to={parentJourney.permalink}>
      <Flex alignItems="center" mb="2">
        {parentJourney.icon && (
          <JourneyIcon icon={parentJourney.icon} mr="1" color="grey5" />
        )}
        <Body color="grey5">{parentJourney.name}</Body>
      </Flex>
    </Link>
    <Header4 fontWeight={500} mb="2">
      {milestone.name}
    </Header4>
    <SidebarItems items={tileItems} />
    <ActionLink to={milestone.permalink}>
      <Flex alignItems="center">
        <Body color="grey5">See the whole series</Body>
        <ArrowIconToRight ml="1" width="16px" height="16px" color="grey5" />
      </Flex>
    </ActionLink>
  </Box>
)

interface Props {
  journey: JourneyPageFragment | MilestonePageFragment
  journeyTile: JourneyTileFragment
}

const makeTileItems = (tiles: JourneyTileFragment['nextTiles']) =>
  tiles
    ?.map(
      (tile) =>
        tile?.tile && {
          id: tile.id,
          name: tile.tile.title,
          permalink: tile.permalink,
        }
    )
    .filter((item) => item !== null && item !== undefined)

const SidebarContents = ({journey, journeyTile}: Props) => {
  const nextTiles = journeyTile.nextTiles || []
  const nextMilestones = journeyTile.nextMilestones || []
  const parentJourney =
    journey.__typename === 'ContentfulJourney' ? journey : journey.journey[0]
  const isLastTile = nextTiles?.length === 0
  const currentMilestoneIndex = findIndex(
    nextMilestones,
    (m) => m.id === journey.id
  )
  const isLastMilestone = currentMilestoneIndex === nextMilestones.length - 1

  if (journey.__typename === 'ContentfulJourney') {
    return (
      <NextMilestones
        milestones={nextMilestones.slice(0, 3)}
        parentJourney={parentJourney}
      />
    )
  }

  if (isLastMilestone && isLastTile) {
    return (
      <NextMilestones
        milestones={nextMilestones.slice(0, 3)}
        parentJourney={parentJourney}
      />
    )
  }

  if (isLastTile) {
    return (
      <NextMilestones
        milestones={nextMilestones.slice(
          currentMilestoneIndex + 1,
          currentMilestoneIndex + 3
        )}
        parentJourney={parentJourney}
      />
    )
  }

  return (
    <NextTiles
      parentJourney={parentJourney}
      milestone={journey}
      tileItems={makeTileItems(nextTiles)}
    />
  )
}

const JourneyTileSidebar = ({journey, journeyTile}: Props) => {
  const {loggedIn} = useViewer()
  const currentLocation = useLocation()
  const isLms = isLmsImplementation(currentLocation)
  if (isLms) {
    return <></>
  }
  return (
    <SidebarContainer>
      <SidebarContents journey={journey} journeyTile={journeyTile} />
      {!loggedIn && <SidebarLogin />}
    </SidebarContainer>
  )
}

export default JourneyTileSidebar
