import {useEffect} from 'react'

import {useJourneyInteractionMutation} from 'src/graphql-generated'
import useViewer from 'src/hooks/useViewer'

const useTrackJourneyInteraction = (contentType: string, contentId: string) => {
  const {loggedIn} = useViewer()
  const [createInteraction] = useJourneyInteractionMutation()

  useEffect(() => {
    if (!loggedIn) {
      return
    }
    createInteraction({variables: {contentType, contentId}})
  }, [createInteraction, loggedIn, contentType, contentId])
}

export default useTrackJourneyInteraction
